/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-10-08T17:54:26
 */
import type {
  StripeProduct,
  StripeProductCreate,
  StripeProductUpdate,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve stripe products.
 * @summary Read Stripe Products
 */
export const productsReadStripeProducts = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeProduct[]>(
    { url: `/api/v1/products/`, method: "GET" },
    options,
  );
};
/**
 * Create new stripe product.
 * @summary Create Stripe Product
 */
export const productsCreateStripeProduct = (
  stripeProductCreate: StripeProductCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeProduct>(
    {
      url: `/api/v1/products/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: stripeProductCreate,
    },
    options,
  );
};
/**
 * Retrieve all stripe products.
 * @summary Read All Stripe Products
 */
export const productsReadAllStripeProducts = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeProduct[]>(
    { url: `/api/v1/products/all`, method: "GET" },
    options,
  );
};
/**
 * Update a stripe product.
 * @summary Update Stripe Product
 */
export const productsUpdateStripeProduct = (
  id: number,
  stripeProductUpdate: StripeProductUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeProduct>(
    {
      url: `/api/v1/products/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: stripeProductUpdate,
    },
    options,
  );
};
/**
 * Delete a stripe product.
 * @summary Delete Stripe Product
 */
export const productsDeleteStripeProduct = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripeProduct>(
    { url: `/api/v1/products/${id}`, method: "DELETE" },
    options,
  );
};
export type ProductsReadStripeProductsResult = NonNullable<
  Awaited<ReturnType<typeof productsReadStripeProducts>>
>;
export type ProductsCreateStripeProductResult = NonNullable<
  Awaited<ReturnType<typeof productsCreateStripeProduct>>
>;
export type ProductsReadAllStripeProductsResult = NonNullable<
  Awaited<ReturnType<typeof productsReadAllStripeProducts>>
>;
export type ProductsUpdateStripeProductResult = NonNullable<
  Awaited<ReturnType<typeof productsUpdateStripeProduct>>
>;
export type ProductsDeleteStripeProductResult = NonNullable<
  Awaited<ReturnType<typeof productsDeleteStripeProduct>>
>;
