/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-10-08T17:54:26
 */
import type {
  CreateCheckoutSessionRequest,
  CreateCheckoutSessionResponse,
  StripePricing,
  StripePricingCreate,
  StripePricingUpdate,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve stripe pricings.
 * @summary Read Stripe Pricings
 */
export const pricingsReadStripePricings = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripePricing[]>(
    { url: `/api/v1/pricings/`, method: "GET" },
    options,
  );
};
/**
 * Create new stripe pricing.
 * @summary Create Stripe Pricing
 */
export const pricingsCreateStripePricing = (
  stripePricingCreate: StripePricingCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripePricing>(
    {
      url: `/api/v1/pricings/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: stripePricingCreate,
    },
    options,
  );
};
/**
 * Retrieve all stripe pricings.
 * @summary Read All Stripe Pricings
 */
export const pricingsReadAllStripePricings = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripePricing[]>(
    { url: `/api/v1/pricings/all`, method: "GET" },
    options,
  );
};
/**
 * Update a stripe pricing.
 * @summary Update Stripe Pricing
 */
export const pricingsUpdateStripePricing = (
  id: number,
  stripePricingUpdate: StripePricingUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripePricing>(
    {
      url: `/api/v1/pricings/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: stripePricingUpdate,
    },
    options,
  );
};
/**
 * Delete a stripe pricing.
 * @summary Delete Stripe Pricing
 */
export const pricingsDeleteStripePricing = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<StripePricing>(
    { url: `/api/v1/pricings/${id}`, method: "DELETE" },
    options,
  );
};
/**
 * Create a checkout session.
 * @summary Create Checkout Session
 */
export const pricingsCreateCheckoutSession = (
  createCheckoutSessionRequest: CreateCheckoutSessionRequest,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<CreateCheckoutSessionResponse>(
    {
      url: `/api/v1/pricings/create-checkout-session`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createCheckoutSessionRequest,
    },
    options,
  );
};
export type PricingsReadStripePricingsResult = NonNullable<
  Awaited<ReturnType<typeof pricingsReadStripePricings>>
>;
export type PricingsCreateStripePricingResult = NonNullable<
  Awaited<ReturnType<typeof pricingsCreateStripePricing>>
>;
export type PricingsReadAllStripePricingsResult = NonNullable<
  Awaited<ReturnType<typeof pricingsReadAllStripePricings>>
>;
export type PricingsUpdateStripePricingResult = NonNullable<
  Awaited<ReturnType<typeof pricingsUpdateStripePricing>>
>;
export type PricingsDeleteStripePricingResult = NonNullable<
  Awaited<ReturnType<typeof pricingsDeleteStripePricing>>
>;
export type PricingsCreateCheckoutSessionResult = NonNullable<
  Awaited<ReturnType<typeof pricingsCreateCheckoutSession>>
>;
