/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-10-08T17:54:26
 */
import type {
  BodyFilesCreateFile,
  Directory,
  File,
  FileMinimal,
  FileUpdate,
  FilesReadAllFilesParams,
  FilesReadFilesByParentParams,
  FilesReadFilesParams,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve files.
 * @summary Read Files
 */
export const filesReadFiles = (
  params?: FilesReadFilesParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<FileMinimal[]>(
    { url: `/api/v1/files/`, method: "GET", params },
    options,
  );
};
/**
 * Create new file.
 * @summary Create File
 */
export const filesCreateFile = (
  bodyFilesCreateFile: BodyFilesCreateFile,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("upload_file", bodyFilesCreateFile.upload_file);
  formData.append("parent_id", bodyFilesCreateFile.parent_id.toString());
  formData.append("path", bodyFilesCreateFile.path);

  return apiAxiosInstance<File>(
    {
      url: `/api/v1/files/`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Delete all files.
 * @summary Delete All Files
 */
export const filesDeleteAllFiles = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<File>({ url: `/api/v1/files/`, method: "DELETE" }, options);
};
/**
 * Retrieve files.
 * @summary Read Files By Parent
 */
export const filesReadFilesByParent = (
  parentId: number,
  params?: FilesReadFilesByParentParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<FileMinimal[]>(
    { url: `/api/v1/files/by-parent/${parentId}`, method: "GET", params },
    options,
  );
};
/**
 * Retrieve all files.
 * @summary Read All Files
 */
export const filesReadAllFiles = (
  params?: FilesReadAllFilesParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<File[]>(
    { url: `/api/v1/files/all`, method: "GET", params },
    options,
  );
};
/**
 * Get file by ID.
 * @summary Read File
 */
export const filesReadFile = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<File>({ url: `/api/v1/files/${id}`, method: "GET" }, options);
};
/**
 * Update a file.
 * @summary Update File
 */
export const filesUpdateFile = (
  id: number,
  fileUpdate: FileUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<File>(
    {
      url: `/api/v1/files/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: fileUpdate,
    },
    options,
  );
};
/**
 * Delete a file.
 * @summary Delete File
 */
export const filesDeleteFile = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<File>(
    { url: `/api/v1/files/${id}`, method: "DELETE" },
    options,
  );
};
/**
 * Create new directory.
 * @summary Create Directory
 */
export const filesCreateDirectory = (
  directory: Directory,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<File>(
    {
      url: `/api/v1/files/mkdir`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: directory,
    },
    options,
  );
};
export type FilesReadFilesResult = NonNullable<
  Awaited<ReturnType<typeof filesReadFiles>>
>;
export type FilesCreateFileResult = NonNullable<
  Awaited<ReturnType<typeof filesCreateFile>>
>;
export type FilesDeleteAllFilesResult = NonNullable<
  Awaited<ReturnType<typeof filesDeleteAllFiles>>
>;
export type FilesReadFilesByParentResult = NonNullable<
  Awaited<ReturnType<typeof filesReadFilesByParent>>
>;
export type FilesReadAllFilesResult = NonNullable<
  Awaited<ReturnType<typeof filesReadAllFiles>>
>;
export type FilesReadFileResult = NonNullable<
  Awaited<ReturnType<typeof filesReadFile>>
>;
export type FilesUpdateFileResult = NonNullable<
  Awaited<ReturnType<typeof filesUpdateFile>>
>;
export type FilesDeleteFileResult = NonNullable<
  Awaited<ReturnType<typeof filesDeleteFile>>
>;
export type FilesCreateDirectoryResult = NonNullable<
  Awaited<ReturnType<typeof filesCreateDirectory>>
>;
